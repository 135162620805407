.home-container {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    width: 100%;
}

@media (max-width: 600px) {
    .home-container {
        margin-top: 80px;
    }
}

.motto {
    align-self: center;
    margin: 0px;
    padding: 0px 20px 20px 20px;
    font-size: 5em;
    font-family: "quicksand-bold";
}

@media (max-width: 600px) {
    .motto {
        font-size: 3em;
    }
}

.home-text {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding-bottom: 40px;
}

.description {
    font-size: 1.5em;
    inline-size: 524px;
    overflow-wrap: break-word;
    padding: 20px;
    padding-bottom: 40px;
}

@media (max-width: 600px) {
    .description {
        display: flex;
        justify-content: center;
        font-size: 1.25em;
        max-width: 300px;
    }
}

@media (max-width: 600px) {
    .home-text {
        inline-size: 350px;
    }
}

.kids-home {
    display: flex;
    justify-content: center;
}

.kids-home img {
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 600px) {
    .kids-home {
        max-width: 90%;
        max-height: 90%;
    }
}

.blue-text {
    color: rgb(0, 96, 128);
}

.buttons__container {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    align-items: center;
}

@media (max-width: 600px) {
    .buttons__container {
        justify-content: center;
        flex-flow: column wrap;
    }
}

@media (max-width: 1000px) {
    .contact-button__container {
        display: none;
    }
}

/* Sample Lessons Button */

.button-sample-lessons {
    position: relative;
    background: rgb(255, 166, 0);
    border: none;
    outline: none;
    height: 5em;
    width: 5em;
    font-size: 1em;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -moz-border-radius: 5em;
    -webkit-border-radius: 5em;
    border-radius: 5em;
    transition: all 0.2s ease-out;
}

.button-sample-lessons:hover {
    background: rgb(255, 196, 0);
    transition: all 0.2s ease-out;
}

.button-sample-lessons:before {
    content: "";
    position: absolute;
    top: 1.5em;
    left: 1.67em;
    height: 0;
    border-style: solid;
    border-width: 1em 0 1em 2em;
    border-color: transparent transparent transparent #ffffff;
    transition: 0.218s ease;
}

.button-sample-lessons__text {
    display: inline;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 1.5em;
    text-decoration-line: underline;
    color: black;
}

/* Overview Section */

.overview__container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 75px;
}

@media (max-width: 1408px) {
    .overview__container {
        margin-bottom: 20px;
    }
}

.overview__title {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 100px;
}

.overview__heading {
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-underline-offset: 20px;
    font-size: 2.5em;
    font-weight: bold;
}

.red-flask {
    margin-left: 40px;
}

.red-flask img {
    max-width: 80%;
    max-height: 80%;
    float: right;
}

.overview__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.overview__image {
    display: flex;
    justify-content: center;
    align-self: center;
}

@media (max-width: 336px) {
    .overview__image {
        display: none;
    }
}

.overview__image img {
    max-width: 60%;
    max-height: 60%;
}

.overview__text {
    display: flex;
    align-self: center;
    font-size: 1.5em;
}

@media (max-width: 600px) {
    .overview__text {
        font-size: 1.25em;
    }
}

.overview__text li {
    margin-top: 10px;
    margin-bottom: 10px;
}
