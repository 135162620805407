.video-details__outer-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.video-details__inner-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 55%;
    padding: 30px;
}

@media (max-width: 1500px) {
    .video-details__inner-container {
        width: 65%;
    }
}

@media (max-width: 600px) {
    .video-details__inner-container {
        width: 100%;
        padding: 20px;
    }
}

.video-details__title {
    display: flex;
    justify-content: center;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-underline-offset: 20px;
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 80px;
}

@media (max-width: 530px) {
    .video-details__title {
        font-size: 1.5em;
    }
}

.video-details__video {
    display: flex;
    justify-content: center;
    width: 100%;
}

.video-details__resources {
    display: flex;
    flex-flow: row;
    justify-content: center;
    font-size: 1.5em;
    margin-bottom: 40px;
}

@media (max-width: 600px) {
    .video-details__resources {
        font-size: 1.1em;
        text-align: center;
    }
    .video-details__resources-file-text {
        font-size: 0.75em;
    }
}

.video-details__resources-text {
    display: flex;
    flex-flow: column;
}

.video-details__resources-file {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.video-details__resources-file-text {
    font-size: 0.9em;
    margin-left: 12px;
    color: black;
    text-decoration: none;
}
