.login__outer-container {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 395px);
    height: calc(100vh - 395px);
}

@media (max-device-height: 915px), screen and (orientation: portrait) {
    .login__outer-container {
        min-height: 0px;
        height: 100%;
    }
}

@media (max-width: 690px) {
    .login__outer-container {
        min-height: 0px;
        height: 100%;
    }
}

@media (max-width: 600px) {
    .login__outer-container {
        margin-top: 80px;
    }
}

.login__inner-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    max-width: 500px;
    padding: 0px 20px 20px 20px;
}
