.footer__container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #0075a3;
    color: white;
    padding: 30px;
    flex-shrink: 0;
}

@media (max-width: 925px) {
    .footer__container {
        flex-flow: column nowrap;
    }
}

.footer__container ul {
    display: table;
    list-style: none;
    padding-left: 0px;
    padding-top: 10px;
    margin: 0 auto;
    width: 100%;
}

.footer__container li {
    display: inline;
    justify-content: space-evenly;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media (max-width: 785px) {
    .footer__container li {
        display: block;
    }
}

.footer__main {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

@media (max-width: 500px) {
    .footer__main {
        flex-flow: column wrap;
        justify-content: center;
    }
}

.footer__steam-lab {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex-flow: column wrap;
}

@media (max-width: 500px) {
    .footer__steam-lab {
        max-width: 120px;
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .footer__steam-lab {
        padding-bottom: 20px;
    }
}

.footer__copyright {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: flex-end;
}

.footer__section-title {
    font-weight: bold;
    font-size: 1.25em;
    margin-left: 20px;
    border-bottom-style: solid;
    border-color: white;
    border-width: 1px;
    padding-bottom: 15px;
}

@media (max-width: 500px) {
    .footer__section-title {
        margin: 0px;
    }
}

.white {
    color: white !important;
    margin: 20px;
}

.white:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}

@media (max-width: 500px) {
    .white {
        margin: 0px;
    }
}
