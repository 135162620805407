.about__container {
    display: flex;
    flex-flow: column nowrap;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
}

@media (max-width: 1460px) {
    .about__container {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (max-width: 600px) {
    .about__container {
        margin-top: 80px;
    }
}

.about__heading-container {
    display: flex;
    margin-left: 340px;
}

@media (max-width: 1460px) {
    .about__heading-container {
        margin: 0px;
        justify-content: center;
    }
}

.about__joe-container,
.about__tish-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-left: 60px;
}

.about__tish-container .about__body-text {
    margin-top: 0px;
    padding-top: 120px;
}

@media (max-width: 1460px) {
    .about__tish-container .about__body-text {
        margin-top: 0px;
        padding-top: 20px;
    }
}

@media (max-width: 1460px) {
    .about__joe-container,
    .about__tish-container {
        flex-flow: column nowrap;
        margin-left: 0px;
    }

    .about__tish-container .about__body-text,
    .about__joe-container .about__body-text {
        margin-top: 0px;
    }
}

.about__heading {
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-underline-offset: 20px;
    font-size: 2.5em;
    font-weight: bold;
}

.about__joe-image img,
.about__tish-image img {
    border-radius: 50%;
    margin-top: 100px;
    border-width: 3px;
    border-color: #0075a3;
    border-style: solid;
}

.about__joe-image img,
.about__tish-image img {
    max-width: 230px;
    max-height: 230px;
}

@media (max-width: 1460px) {
    .about__joe-image,
    .about__tish-image {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    .about__joe-image img,
    .about__tish-image img {
        max-width: 300px;
        max-height: 300px;
    }

    .about__tish-image img {
        margin-top: 40px;
    }
}

.about__name-text {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 2em;
    margin: 10px 0px;
}

.about__text {
    margin-left: 40px;
}

@media (max-width: 1460px) {
    .about__text {
        margin: 0px;
    }
}

.about__body-text {
    display: flex;
    vertical-align: center;
    font-size: 1.5em;
    overflow-wrap: break-word;
    padding-top: 75px;
}

@media (max-width: 1460px) {
    .about__body-text {
        padding-top: 20px;
    }
}

@media (max-width: 600px) {
    .about__body-text {
        font-size: 1.25em;
    }
}
