.samples__outer-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}

@media (max-width: 600px) {
    .samples__outer-container {
        margin-top: 85px;
    }
}

.footer_fill {
    min-height: calc(100vh - 395px);
    height: calc(100vh - 395px);
}

@media (max-device-height: 915px), screen and (orientation: portrait) {
    .footer_fill {
        min-height: 0px;
        height: 100%;
    }
}

@media (max-width: 690px) {
    .samples__outer-container {
        min-height: 0px;
    }
}

.samples__inner-container {
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    width: 100%;
}

.video-mask {
    border-radius: 30px;
    margin-bottom: 60px;
    overflow: hidden;
    --webkit-transform: translateZ(0);
}

.samples__video-title {
    display: flex;
    justify-content: center;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-underline-offset: 20px;
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 85px;
}

.samples__videos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
}

@media (max-width: 690px) {
    .samples__videos {
        flex-flow: column nowrap;
        align-items: center;
    }
}

.cards-gap {
    gap: 50px;
}
